import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link, Box, Text, Icon, LinkBox, Section } from "@quarkly/widgets";
import { FaTwitterSquare, FaGithub, FaYoutube } from "react-icons/fa";
const defaultProps = {
	"background": "--color-dark",
	"padding": "50px 0 50px 0",
	"quarkly-title": "Footer-19",
	"border-width": "0 0 .2px 0",
	"border-style": "solid",
	"border-color": "--color-darkL1"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "0px 0px 15px 0px",
			"sm-flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "center",
			"grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0 5px 0px",
			"hover-color": "#a78bfa",
			"href": "0634398028",
			"children": "0634398028"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "white",
			"font": "--base",
			"children": "Vitruka St, 9, Zhytomyr, Zhytomyr Oblast, 10001"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-template-columns": "repeat(5, 1fr)",
			"grid-gap": "16px 24px",
			"md-align-self": "flex-start"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaTwitterSquare,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaGithub,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"href": "/"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "fa",
			"icon": FaYoutube,
			"size": "24px",
			"color": "#c3c8d0",
			"hover-color": "#a78bfa",
			"transition": "background-color 1s ease 0s"
		}
	}
};

const Header1 = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
				</Box>
				<Box {...override("box3")}>
					<Text {...override("text")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<LinkBox {...override("linkBox")}>
					<Icon {...override("icon")} />
				</LinkBox>
				<LinkBox {...override("linkBox1")}>
					<Icon {...override("icon1")} />
				</LinkBox>
				<LinkBox {...override("linkBox2")}>
					<Icon {...override("icon2")} />
				</LinkBox>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header1, { ...Section,
	defaultProps,
	overrides
});
export default Header1;